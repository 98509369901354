#write-and-delete {
  color: #fff;
  font-family: Courier New, Courier, monospace;
  font-weight: bold;
  font-size: var(--font-xs);
  background-color: #0006;
  border-radius: 5px;
  width: 100%;
  max-width: 450px;
  margin: auto;
  padding: 1rem;
  display: block;
  box-shadow: inset 0 0 5px #222;
}

@media (width <= 466px) {
  #write-and-delete {
    border-radius: 0;
  }
}
/*# sourceMappingURL=write-and-delete.dc75bbf0.css.map */
