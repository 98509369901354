#write-and-delete {
  box-shadow: 0 0 5px #222 inset;
  background-color: #00000066;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: var(--font-xs);
  /* color: var(--p400); */
  padding: 1rem;
  width: 100%;
  max-width: 450px;
  margin: auto;
  display: block;
  border-radius: 5px;
}

@media (max-width: 466px) {
  #write-and-delete {
    border-radius: 0;
  }
}